<template>
  <div class="px-2 py-5">
    <h1 class="title">Indice de la severidad del área de Psoriasis (PASI)</h1>
    <b-message type="is-info">
      PASI combina la valoración de la severidad de lesiones y el área afectada
      en una sola cifra entre los valores de 0 (ninguna enfermedad) a 72
      (enfermedad máxima).
    </b-message>

    <h1 class="title has-text-centered mt-4">Cabeza y cuello</h1>
    <br />
    <PasiDefaultForm
      areaPercentage="0.1"
      module="cabeza"
      :question="questions.HEAD"
      :answers="answers.HEAD"
      :temporalData="temporal.head"
      :queryMode="queryMode"
      :queryAnswers="headQueryAnsers"
      @formInfo="setInfo"
    />
    <hr />
    <h1 class="title has-text-centered mt-5">Tronco</h1>
    <br />
    <PasiDefaultForm
      areaPercentage="0.3"
      module="tronco"
      :question="questions.TRUK"
      :answers="answers.TRUK"
      :temporalData="temporal.truk"
      :queryAnswers="trukQueryAnsers"
      :queryMode="queryMode"
      @formInfo="setInfo"
    />
    <hr />
    <h1 class="title has-text-centered mt-5">Extremidades superiores</h1>
    <br />
    <PasiDefaultForm
      areaPercentage="0.2"
      module="extremidadesSuperiores"
      :question="questions.SUPERIOR_LIMBS"
      :answers="answers.SUPERIOR_LIMBS"
      :temporalData="temporal.superiorLimbs"
      :queryMode="queryMode"
      :queryAnswers="superiorQueryAnsers"
      @formInfo="setInfo"
    />
    <hr />
    <h1 class="title has-text-centered mt-5">Extremidades inferiores</h1>
    <br />
    <PasiDefaultForm
      areaPercentage="0.4"
      module="extremidadesInferiores"
      :question="questions.LOWER_LIMBS"
      :answers="answers.LOWER_LIMBS"
      :temporalData="temporal.lowerLimbs"
      :queryMode="queryMode"
      :queryAnswers="lowerQueryAnsers"
      @formInfo="setInfo"
    />

    <div class="block has-text-centered title mt-6" v-if="!queryMode">
      El resultado es: {{ score }}
      <span v-if="isIncompleted">(Incompleto)</span>
    </div>
    <div class="block has-text-centered title mt-6" v-if="queryMode">
      El resultado es: {{ result }}
    </div>

    <div class="buttons is-centered" v-if="!queryMode">
      <b-button class="clear-button" icon-right="block-helper" @click="clear"
        >Limpiar</b-button
      >
      <b-button class="save-button" icon-right="check-bold" @click="validate"
        >Guardar</b-button
      >
    </div>
  </div>
</template>

<script>
import PasiDefaultForm from "../../../components/Forms/PasiDefaultForm.vue";
import { PASI as questions } from "../../../data/questions";
import { PASI as answers } from "../../../data/answers";

export default {
  components: { PasiDefaultForm },
  data() {
    return {
      questions: questions,
      answers: answers,
      activeStep: 0,
      showSocial: true,
      isAnimated: true,
      isRounded: true,
      isStepsClickable: true,
      hasNavigation: true,
      customNavigation: true,
      isHeadAndNeckComplete: false,
      isTrukComplete: false,
      isSuperiorLimbsComplete: false,
      isLoweLimbsComplete: false,
      prevIcon: "chevron-left",
      nextIcon: "chevron-right",
      labelPosition: "bottom",
      mobileMode: "minimalist",
      headModule: 0,
      trukModule: 0,
      superiorLimbs: 0,
      loweLimbs: 0,
      headAnswers: [],
      trukAnswers: [],
      superiorLimbsAnswers: [],
      loweLimbsAnswers: [],
      answersConsolidate: [],
      temporal: {},
      queryMode: false,
      headQueryAnsers: [],
      trukQueryAnsers: [],
      superiorQueryAnsers: [],
      lowerQueryAnsers: [],
      result: "",
    };
  },
  async created() {
    if (!this.patient && !this.$route.params.surveyId) {
      this.$router.push({ name: "PasiList" }).then(() => {
        this.showToast("No has seleccionado un paciente.", "is-warning", 3000);
      });
    }

    if (this.$route.params.surveyId) {
      this.queryMode = true;
      let data = await this.getSurveyData();
      this.setEvaluationAnswers(data);
    }

    if (this.$route.params.temporalData) {
      this.temporal = this.$route.params.temporalData;
    }
  },
  computed: {
    score() {
      let result = parseFloat(
        this.headModule + this.trukModule + this.superiorLimbs + this.loweLimbs
      ).toFixed(1);

      if (result == 0.0) {
        return 0;
      } else {
        return result;
      }
    },
    isIncompleted() {
      return (
        this.isHeadAndNeckComplete == false ||
        this.isTrukComplete == false ||
        this.isSuperiorLimbsComplete == false ||
        this.isLoweLimbsComplete == false
      );
    },
    userId: {
      get() {
        return this.$store.state.user.userData?.id;
      },
    },
    patient: {
      get() {
        return this.$store.state.patient.patientData;
      },
    },
  },
  methods: {
    setInfo(moduleData) {
      switch (moduleData.module) {
        case "cabeza":
          this.headModule = moduleData.score;
          this.headAnswers = moduleData.answers;
          this.temporal.head = moduleData.answers;
          this.isHeadAndNeckComplete = moduleData.isComplete;
          break;
        case "tronco":
          this.trukModule = moduleData.score;
          this.trukAnswers = moduleData.answers;
          this.temporal.truk = moduleData.answers;
          this.isTrukComplete = moduleData.isComplete;
          break;
        case "extremidadesSuperiores":
          this.superiorLimbs = moduleData.score;
          this.superiorLimbsAnswers = moduleData.answers;
          this.temporal.superiorLimbs = moduleData.answers;
          this.isSuperiorLimbsComplete = moduleData.isComplete;
          break;
        case "extremidadesInferiores":
          this.loweLimbs = moduleData.score;
          this.loweLimbsAnswers = moduleData.answers;
          this.temporal.lowerLimbs = moduleData.answers;
          this.isLoweLimbsComplete = moduleData.isComplete;

          break;
        default:
          break;
      }
      this.saveTermporalSulrvey();
    },
    cleanForms() {
      this.isHeadAndNeckComplete = false;
      this.isTrukComplete = false;
      this.isSuperiorLimbsComplete = false;
      this.isLoweLimbsComplete = false;
      this.activeStep = 0;
      this.answersConsolidate = [];
      this.headAnswers = [];
      this.trukAnswers = [];
      this.loweLimbsAnswers = [];
      this.superiorLimbsAnswers = [];
      this.$global.$emit("clearForm");
    },
    clear() {
      this.cleanForms();
      this.clearTemporalSurveys();
      this.showUndoDialog("Se limpiaron los datos de  la encuesta PASI.");
    },
    async save() {
      try {
        this.changeLoaderStatus(true);
        this.setAnswers();
        let data = {
          surveyCode: "PASI",
          result: String(this.score),
          appreciation: "",
          doctorId: String(this.userId),
          patientId: String(this.patient.document),
          detail: this.answersConsolidate,
        };

        await this.$surveyService.saveSurvey(data);
        this.changeLoaderStatus(false);
        this.showToast(
          "Encuesta enviada satisfactoriamente.",
          "is-success",
          3000
        );
        this.scrollTop();
        this.cleanForms();
        this.clearTemporalSurveys();
      } catch (error) {
        this.changeLoaderStatus(false);
        this.showToast("Ha ocurrido un error inesperado.", "is-danger", 3000);
      }
    },
    setAnswers() {
      this.headAnswers.forEach((element) => {
        this.answersConsolidate.push(element);
      });

      this.trukAnswers.forEach((element) => {
        this.answersConsolidate.push(element);
      });

      this.loweLimbsAnswers.forEach((element) => {
        this.answersConsolidate.push(element);
      });

      this.superiorLimbsAnswers.forEach((element) => {
        this.answersConsolidate.push(element);
      });
    },
    validate() {
      if (!this.isIncompleted) {
        this.save();
      } else {
        this.showToast("Por favor completa la encuesta.", "is-warning", 3000);
      }
    },
    saveTermporalSulrvey() {
      this.$session.set("temporalSurvey", {
        module: {
          name: "PASI",
          route: "Pasi",
        },
        survey: {
          head: this.temporal.head,
          truk: this.temporal.truk,
          superiorLimbs: this.temporal.superiorLimbs,
          lowerLimbs: this.temporal.lowerLimbs,
        },
      });
    },
    setEvaluationAnswers(data) {
      this.result = data.result;
      data.evaluationDetails.forEach((survey) => {
        if (survey.question.code.includes("HEAD")) {
          this.headQueryAnsers.push(survey);
        }

        if (survey.question.code.includes("TRUK")) {
          this.trukQueryAnsers.push(survey);
        }

        if (survey.question.code.includes("SUPERIOR")) {
          this.superiorQueryAnsers.push(survey);
        }

        if (survey.question.code.includes("LOWER")) {
          this.lowerQueryAnsers.push(survey);
        }
      });
      this.$global.$emit("setQueryAnswers");
    },
  },
};
</script>
