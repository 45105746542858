<template>
  <div>
    <div class="block">
      <span>
        Porcentaje de área de la piel implicada.
      </span>

      <div class="columns mt-2">
        <div class="column is-2 has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column is-1">
          <b-radio
            v-model="area"
            @input="emitData"
            :disabled="queryMode"
            :name="questionKeys[0]"
            :native-value="areaAnswers[0][1]"
          >
            el 0% de área implicada
          </b-radio>
        </div>
        <div class="column is-1">
          <b-radio
            v-model="area"
            @input="emitData"
            :disabled="queryMode"
            :name="questionKeys[0]"
            :native-value="areaAnswers[1][1]"
          >
            {{ grade1Area }}
          </b-radio>
        </div>
        <div class="column is-1">
          <b-radio
            v-model="area"
            @input="emitData"
            :disabled="queryMode"
            :name="questionKeys[0]"
            :native-value="areaAnswers[2][1]"
          >
            10-29% del área implicada
          </b-radio>
        </div>
        <div class="column is-1">
          <b-radio
            v-model="area"
            @input="emitData"
            :disabled="queryMode"
            :name="questionKeys[0]"
            :native-value="areaAnswers[3][1]"
          >
            30-49% del área implicada
          </b-radio>
        </div>

        <div class="column is-1">
          <b-radio
            v-model="area"
            @input="emitData"
            :disabled="queryMode"
            :name="questionKeys[0]"
            :native-value="areaAnswers[4][1]"
          >
            50-69% del área implicada
          </b-radio>
        </div>
        <div class="column is-1">
          <b-radio
            v-model="area"
            @input="emitData"
            :disabled="queryMode"
            :name="questionKeys[0]"
            :native-value="areaAnswers[5][1]"
          >
            70-89% del área implicada
          </b-radio>
        </div>
        <div class="column is-1">
          <b-radio
            v-model="area"
            @input="emitData"
            :disabled="queryMode"
            :name="questionKeys[0]"
            :native-value="areaAnswers[6][1]"
          >
            90-100% del área implicada
          </b-radio>
        </div>
      </div>
    </div>
    <!-- <div class="block">
      <span>
        Picor
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="itching"
            @input="emitData"
            :disabled="queryMode"
            :name="questionKeys[1]"
            :native-value="itchingAnswers[0][1]"
          >
            Ninguno
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="itching"
            @input="emitData"
            :disabled="queryMode"
            :name="questionKeys[1]"
            :native-value="itchingAnswers[1][1]"
          >
            Leve
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="itching"
            @input="emitData"
            :disabled="queryMode"
            :name="questionKeys[1]"
            :native-value="itchingAnswers[2][1]"
          >
            Moderado
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="itching"
            @input="emitData"
            :disabled="queryMode"
            :name="questionKeys[1]"
            :native-value="itchingAnswers[3][1]"
          >
            Severo
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="itching"
            @input="emitData"
            :disabled="queryMode"
            :name="questionKeys[1]"
            :native-value="itchingAnswers[4][1]"
          >
            Muy severo
          </b-radio>
        </div>
      </div>
    </div> -->
    <div class="block">
      <span>
        Eritema
      </span>

      <div class="columns mt-2">
        <div class="column is-2 has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column is-1">
          <b-radio
            v-model="erythema"
            @input="emitData"
            :disabled="queryMode"
            :name="questionKeys[2]"
            :native-value="erythemaAnswers[0][1]"
          >
            Ninguno
          </b-radio>
        </div>
        <div class="column is-1">
          <b-radio
            v-model="erythema"
            @input="emitData"
            :disabled="queryMode"
            :name="questionKeys[2]"
            :native-value="erythemaAnswers[1][1]"
          >
            Leve
          </b-radio>
        </div>
        <div class="column is-1">
          <b-radio
            v-model="erythema"
            @input="emitData"
            :disabled="queryMode"
            :name="questionKeys[2]"
            :native-value="erythemaAnswers[2][1]"
          >
            Moderado
          </b-radio>
        </div>
        <div class="column is-1">
          <b-radio
            v-model="erythema"
            @input="emitData"
            :disabled="queryMode"
            :name="questionKeys[2]"
            :native-value="erythemaAnswers[3][1]"
          >
            Severo
          </b-radio>
        </div>

        <div class="column is-2">
          <b-radio
            v-model="erythema"
            @input="emitData"
            :disabled="queryMode"
            :name="questionKeys[2]"
            :native-value="erythemaAnswers[4][1]"
          >
            Muy severo
          </b-radio>
        </div>
      </div>
    </div>
    <div class="block">
      <span>
        Induración/Grosor
      </span>

      <div class="columns mt-2">
        <div class="column is-2 has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column is-1">
          <b-radio
            v-model="induration"
            @input="emitData"
            :disabled="queryMode"
            :name="questionKeys[4]"
            :native-value="indurationAnswers[0][1]"
          >
            Ninguno
          </b-radio>
        </div>
        <div class="column is-1">
          <b-radio
            v-model="induration"
            @input="emitData"
            :disabled="queryMode"
            :name="questionKeys[4]"
            :native-value="indurationAnswers[1][1]"
          >
            Leve
          </b-radio>
        </div>
        <div class="column is-1">
          <b-radio
            v-model="induration"
            @input="emitData"
            :disabled="queryMode"
            :name="questionKeys[4]"
            :native-value="indurationAnswers[2][1]"
          >
            Moderado
          </b-radio>
        </div>
        <div class="column is-1">
          <b-radio
            v-model="induration"
            @input="emitData"
            :disabled="queryMode"
            :name="questionKeys[4]"
            :native-value="indurationAnswers[3][1]"
          >
            Severo
          </b-radio>
        </div>

        <div class="column is-2">
          <b-radio
            v-model="induration"
            :name="questionKeys[4]"
            @input="emitData"
            :disabled="queryMode"
            :native-value="indurationAnswers[4][1]"
          >
            Muy severo
          </b-radio>
        </div>
      </div>
    </div>
    <div class="block">
      <span>
        Descamación
      </span>

      <div class="columns mt-2">
        <div class="column is-2 has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column is-1">
          <b-radio
            v-model="peeling"
            @input="emitData"
            :disabled="queryMode"
            :name="questionKeys[3]"
            :native-value="peelingAnswers[0][1]"
          >
            Ninguno
          </b-radio>
        </div>
        <div class="column is-1">
          <b-radio
            v-model="peeling"
            @input="emitData"
            :disabled="queryMode"
            :name="questionKeys[3]"
            :native-value="peelingAnswers[1][1]"
          >
            Leve
          </b-radio>
        </div>
        <div class="column is-1">
          <b-radio
            v-model="peeling"
            @input="emitData"
            :disabled="queryMode"
            :name="questionKeys[3]"
            :native-value="peelingAnswers[2][1]"
          >
            Moderado
          </b-radio>
        </div>
        <div class="column is-1">
          <b-radio
            v-model="peeling"
            @input="emitData"
            :disabled="queryMode"
            :name="questionKeys[3]"
            :native-value="peelingAnswers[3][1]"
          >
            Severo
          </b-radio>
        </div>

        <div class="column is-2">
          <b-radio
            v-model="peeling"
            @input="emitData"
            :disabled="queryMode"
            :name="questionKeys[3]"
            :native-value="peelingAnswers[4][1]"
          >
            Muy severo
          </b-radio>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    areaPercentage: {
      type: String,
      default: "0.0",
    },
    module: {
      type: String,
      default: "Cabeza",
    },
    question: {
      type: Object,
      default: null,
    },
    answers: {
      type: Object,
      default: null,
    },
    temporalData: {
      type: Array,
      default: null,
    },
    queryMode: {
      type: Boolean,
      default: false,
    },
    queryAnswers: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      grade1Area: "< el 10% de área implicada",
      area: "",
      itching: "",
      erythema: "",
      peeling: "",
      induration: "",
      questionKeys: [],
      areaAnswers: [],
      itchingAnswers: [],
      erythemaAnswers: [],
      peelingAnswers: [],
      indurationAnswers: [],
      restoreData: {},
    };
  },
  created() {
    this.setKeys();

    this.$global.$on("setQueryAnswers", () => {
      this.setQueryAnswers();
    });

    if (this.temporalData && this.temporalData.length > 0) {
      this.area = this.temporalData[0].value;
      this.erythema = this.temporalData[1].value;
      this.itching = this.temporalData[2].value;
      this.peeling = this.temporalData[3].value;
      this.induration = this.temporalData[4].value;

      this.emitData();
    }

    if (!this.temporalData) {
      this.initialAnswer();
    }

    this.$global.$on("restore", () => {
      this.restoreForm();
    });

    this.$global.$on("clearForm", () => {
      this.clear();
    });
  },
  computed: {
    score() {
      return (
        // parseInt(this.itching || 0) +
        (parseInt(this.erythema || 0) +
          parseInt(this.peeling || 0) +
          parseInt(this.induration || 0)) *
        this.area *
        parseFloat(this.areaPercentage)
      );
    },
    isIncompleted() {
      return (
        // this.itching === "" ||
        this.erythema === "" ||
        this.peeling === "" ||
        this.induration === "" ||
        this.area === ""
      );
    },
  },
  methods: {
    setKeys() {
      let areaKey = Object.keys(this.answers)[0];
      let erythemaKey = Object.keys(this.answers)[1];
      let itchingKey = Object.keys(this.answers)[2];
      let peelingKey = Object.keys(this.answers)[3];
      let indurationKey = Object.keys(this.answers)[4];

      this.questionKeys = Object.keys(this.question);

      this.areaAnswers = Object.entries(this.answers[areaKey]);
      this.erythemaAnswers = Object.entries(this.answers[erythemaKey]);
      this.itchingAnswers = Object.entries(this.answers[itchingKey]);
      this.peelingAnswers = Object.entries(this.answers[peelingKey]);
      this.indurationAnswers = Object.entries(this.answers[indurationKey]);
    },
    clear() {
      this.restoreData.area = this.area;
      this.restoreData.erythema = this.erythema;
      this.restoreData.itching = this.itching;
      this.restoreData.peeling = this.peeling;
      this.restoreData.induration = this.induration;

      // this.area = "";
      // this.erythema = "";
      // this.itching = "";
      // this.peeling = "";
      // this.induration = "";
      this.initialAnswer();
      this.emitData();
    },
    emitData() {
      this.$emit("formInfo", {
        score: this.score,
        module: this.module,
        isComplete: !this.isIncompleted,
        answers: this.setAnswers(),
      });
    },
    setAnswers() {
      let area = null;
      let erythema = null;
      let itching = null;
      let peeling = null;
      let induration = null;

      this.areaAnswers.forEach((element) => {
        if (element[1] == this.area) {
          area = {
            questionCode: Object.keys(this.answers)[0],
            answerCode: element[0],
            value: this.area,
          };
        }
      });

      this.erythemaAnswers.forEach((element) => {
        if (element[1] == this.erythema) {
          erythema = {
            questionCode: Object.keys(this.answers)[1],
            answerCode: element[0],
            value: this.erythema,
          };
        }
      });

      this.itchingAnswers.forEach((element) => {
        if (element[1] == this.itching) {
          itching = {
            questionCode: Object.keys(this.answers)[2],
            answerCode: element[0],
            value: this.itching,
          };
        }
      });

      this.peelingAnswers.forEach((element) => {
        if (element[1] == this.peeling) {
          peeling = {
            questionCode: Object.keys(this.answers)[3],
            answerCode: element[0],
            value: this.peeling,
          };
        }
      });

      this.areaAnswers.forEach((element) => {
        if (element[1] == this.induration) {
          induration = {
            questionCode: Object.keys(this.answers)[4],
            answerCode: element[0],
            value: this.induration,
          };
        }
      });

      return [area, erythema, itching, peeling, induration];
    },
    restoreForm() {
      this.area = this.restoreData.area;
      this.erythema = this.restoreData.erythema;
      this.itching = this.restoreData.itching;
      this.peeling = this.restoreData.peeling;
      this.induration = this.restoreData.induration;
      this.emitData();
    },
    setQueryAnswers() {
      this.queryAnswers.forEach((survey) => {
        if (survey.question.code.includes("AREA")) {
          this.area = survey.value;
        }

        if (survey.question.code.includes("ERITEMA")) {
          this.erythema = survey.value;
        }

        if (survey.question.code.includes("PICOR")) {
          this.itching = survey.value;
        }

        if (survey.question.code.includes("DESCAMACION")) {
          this.peeling = survey.value;
        }

        if (survey.question.code.includes("INDURACION")) {
          this.induration = survey.value;
        }
      });
    },
    initialAnswer() {
      this.area = this.areaAnswers[0][1];
      this.erythema = this.erythemaAnswers[0][1];
      this.itching = this.itchingAnswers[0][1];
      this.peeling = this.peelingAnswers[0][1];
      this.induration = this.indurationAnswers[0][1];
      this.emitData();
    },
  },
};
</script>

<style></style>
